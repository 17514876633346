import photo1 from '../img/default/thankyous/1GroupPhoto.jpg'
import photo2 from '../img/default/thankyous/2Table1.jpeg'
import photo3 from '../img/default/thankyous/3Table2.jpeg'
import photo4 from '../img/default/thankyous/4Table3.jpeg'
import photo5 from '../img/default/thankyous/5Table4.jpeg'
import photo6 from '../img/default/thankyous/6Table5.jpeg'
import photo7 from '../img/default/thankyous/7Table6.jpeg'
import photo8 from '../img/default/thankyous/8Table7.jpeg'
import photo9 from '../img/default/thankyous/9Table8.jpeg'
import photo10 from '../img/default/thankyous/10Table9.jpeg'
import photo11 from '../img/default/thankyous/11Table10.jpeg'
import photo12 from '../img/default/thankyous/12AdamFam.jpeg'
import photo13 from '../img/default/thankyous/13WeddingParty.jpeg'
import photo14 from '../img/default/thankyous/14EmFam.jpeg'

import PhotoGallery from './PhotoGallery'

function ThankYous() {
    let photoGalleryImages = [
        {"source": photo1, "thumb": photo1, "width": 2048, "height": 1365},
        {"source": photo2, "thumb": photo2,"width": 2048, "height": 1365},
        {"source": photo3, "thumb": photo3,"width": 2048, "height": 1365},
        {"source": photo4, "thumb": photo4,"width": 2048, "height": 1365},
        {"source": photo5, "thumb": photo5,"width": 2048, "height": 1365},
        {"source": photo6, "thumb": photo6,"width": 2048, "height": 1365},

        {"source": photo7, "thumb": photo7,"width": 2048, "height": 1365},
        {"source": photo8, "thumb": photo8,"width": 2048, "height": 1365},
        {"source": photo9, "thumb": photo9,"width": 2048, "height": 1365},
        {"source": photo10, "thumb": photo10,"width": 2048, "height": 1365},
        {"source": photo11, "thumb": photo11,"width": 2048, "height": 1365},
        {"source": photo12, "thumb": photo12,"width": 2048, "height": 1365},
        {"source": photo13, "thumb": photo13,"width": 2048, "height": 1365},
        {"source": photo14, "thumb": photo14,"width": 2048, "height": 1365},
    ]

    return (
        <div>
            <h2>Thanks for celebrating with us!</h2>
            <PhotoGallery offset={true} images={photoGalleryImages}/>
            <div><h3>Special thanks to our families and wedding party!</h3></div>
        </div>
    )
}

export default ThankYous;