import BannerPlaceholder from '../img/bannerPlaceholder.jpg';
import ConflictKitchen from '../img/Memories/Memories/1 - Conflict Kitchen.JPG';
import Soergel from '../img/Memories/2 - Soergel_s.png';
import GrandCanyon from '../img/Memories/3 - AZ Grand Canyon 2016.png';
import Disneyland from '../img/Memories/3.1 - Disneyland.png';
import Parasailing from '../img/Memories/4 - Miami Parasailing 1.JPG';
import Miami from '../img/Memories/4.1 - Miami Wolves.JPG';
import Anni from '../img/Memories/6- Anni Year 1.png';
import Lantern from '../img/Memories/7 - Lantern Festival.JPG'
import NewYorkNYE from '../img/Memories/8 - NYC NYE.JPG'
import LionKing from '../img/Memories/8.1 - Broadway Lion King.jpg'
import GoldenGate from '../img/Memories/9 - San Fran Bridge.JPG'
import SanFran from '../img/Memories/9.1 - MOIC Swing.JPG'
import Sprinkles from '../img/Memories/9.2 - MOIC NYC 2.jpg'
import Friends from '../img/Memories/9.4 - Friends Apartment.jpg'
import CMU2 from '../img/Memories/10.1 - Adam Graduation 1.JPG'
import CMU from '../img/Memories/10 - Adam Graduation 3.JPG'
import SecondAnni from '../img/Memories/11 - Anni Capital Grille.png'
import Bean from '../img/Memories/13 - Chicago Bean 1.png'
import Chicago from '../img/Memories/13.1 - Chicago Hamilton.png'
import MagicRock from '../img/Memories/14 - Vegas Seven Magic Mountains.png'
import Vegas from '../img/Memories/14.1 - Vegas Celine Dion.png'
import SanDiego from '../img/Memories/15 - San Diego.jpg'
import ThirdAnni from '../img/Memories/15.5 - Anni Spoon.jpg'
import Austin from '../img/Memories/16 - Austin Mural.jpg'
import ChicagoMural from '../img/Memories/16.1 - Chicago 2.jpg'
import SeattleMural from '../img/Memories/16.2 - Seattle Mural.jpg'
import Acadia from '../img/Memories/17 - Acadia.jpg'
import IceCastle from '../img/Memories/18 - Ice Castle.jpg'
import MovingTruck from '../img/Memories/19 - Moving.png'
import GrandCanyonFamily from '../img/Memories/20.1 - AZ Grand Canyon 2022 1.jpg'
import GrandCanyonFamily2 from '../img/Memories/20.2 - AZ Grand Canyon 1.jpg'
import Lift from '../img/Memories/20 - AZ Lift.jpg'
import Proposal from '../img/Memories/21 - Asheville Proposal 1.jpg'
import Proposal2 from '../img/Memories/21.1 - Asheville Proposal 2.jpg'
import Proposal3 from '../img/Memories/21.2 - Asheville Proposal 3.jpg'
import Proposal4 from '../img/Memories/21.5 - Asheville Proposal 5.jpg'
import Proposal5 from '../img/Memories/21.6 - Asheville 2.jpg'
import CherryBlossom from '../img/Memories/22 - DC Cherry Blossoms 1.jpg'
import CherryBlossom2 from '../img/Memories/22.1 - DC Cherry Blossoms 2.jpg'
import Disney from '../img/Memories/23 - Disney World.jpg'
import Bayoons from '../img/Memories/24 - AZ Hot Air Balloon 2.jpg'
import Bayoons2 from '../img/Memories/24.1 - AZ Hot Air Balloon 1.jpg'
import Bayoons3 from '../img/Memories/24.2 - AZ Hot Air Balloon Ring.jpg'
import House from '../img/Memories/25 - House.jpg'
import Mario from '../img/Memories/26 - Super Nintendo World.jpg'
import Mario2 from '../img/Memories/26.1 - Super Nintendo World 2.jpg'
import Mario3 from '../img/Memories/26.2 - Super Nintendo World 3.jpg'
import Mario4 from '../img/Memories/26.4 - Super Nintendo World 4.jpg'
import Stonebridge from '../img/Memories/27 - Gardens of Stonebridge.jpg'
import AdamBirthday from '../img/Memories/AdamBirthday.png'

import married from '../img/Memories/Memories/0 Wedding.jpeg'

import TimelineElement from './TimelineElement'

function TimelineWebUpdated() {
    return(
        <div className=''>
                <div>
                <h2>Our Favorite Memories</h2>
                    <h3 className="justify-content-center" style={{"textAlign":"center"}}>Year 1</h3>
                    <div className="row timeline">
                        <TimelineElement fit={false} index={1} description="One of the first off-campus places we hung out at was Soergel Orchards. It has become an annual tradition for us to visit each fall and pick apples, visit the pumpkin patch, and enjoy our favorite apple cider." image={[Soergel]}/>
                        
                        <TimelineElement fit={false} index={2} description="For the holidays, Emory traveled to Arizona to meet Adam’s family for the first time, and we all took a trip to the Grand Canyon and then Disneyland." image={[GrandCanyon, Disneyland]}/>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={2} description="For spring break, we traveled to Miami, FL and went parasailing and played with baby wolves at the now closed Safari Edventure." image={[Parasailing, Miami]}/>
                    
                        <TimelineElement fit={false} index={3} description="Emory arranged “monthiversary foodventures” starting from 9 months at Nine on Nine (now closed), Ten Penny (now closed), and Eleven. She couldn’t find a “12” or “1” restaurant, but we celebrated our first anniversary at Spoon (haha), which became our all-time favorite Pittsburgh restaurant. Unfortunately, they closed during the pandemic, but it will always be our favorite." image={[Anni]}/>

                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div>
                    <h3 style={{"textAlign":"center"}}>Year 2</h3>
                    <div className="row timeline">
                        <TimelineElement fit={false} index={1} description="We went to the Lantern Fest, and it was like Tangled in real life!" image={[Lantern]}/>
                        
                        <TimelineElement fit={false} index={2} description="We traveled to New York City for New Year’s Eve, and it ended up being the record coldest year. We couldn’t do much outside, so we ended up going to the TKTS stand every morning and getting tickets to whatever broadway show was available for that day. We saw 5 shows that week, and that started our obsession with seeing musicals—we’ve seen over 30 together!" image={[NewYorkNYE, LionKing]}/>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={2} description="We traveled to San Francisco for spring break and visited the Golden Gate Bridge and went to the Museum of Ice Cream. This was the start of us visiting many pop-up museums." image={[GoldenGate, SanFran, Sprinkles, Friends]}/>
                    
                        <div className="offset-timeline-element">
                            <TimelineElement fit={true} index={3} description="Adam graduated from his Masters program at Carnegie Mellon University and accepted a job in Boston. The move was hard, but we made long distance work." image={[CMU, CMU2]}/>

                            <TimelineElement fit={true} index={1} description="We celebrated our second anniversary at Capital Grille in downtown Pittsburgh." image={[SecondAnni]}/>
                        </div>

                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div>
                    <h3 style={{"textAlign":"center"}}>Year 3</h3>
                    <div className="row timeline">
                        <TimelineElement fit={false} index={1} description="We became obsessed with Hamilton and traveled to Chicago, IL to see it." image={[Bean, Chicago]}/>
                        
                        <TimelineElement fit={false} index={2} description="We traveled to Las Vegas, NV for Emory’s 26th birthday to see Celine Dion’s residency and also visited Seven Magic Mountains and Red Rock Canyon." image={[MagicRock, Vegas]}/>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={2} description="Adam traveled to San Diego, CA for a work trip, and Emory joined for a few days. We visited Balboa Park and both fell in love with the city and hope to call it home in the future." image={[SanDiego]}/>
                    
                        <TimelineElement fit={false} index={3} description="We celebrated our 3rd anniversary back at Spoon." image={[ThirdAnni]}/>

                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div>
                    <h3 style={{"textAlign":"center"}}>Year 4</h3>
                    <div className="row timeline">
                    
                        <TimelineElement fit={false} index={1} description="We took a trip to visit friends in Austin, TX and enjoyed seeing the sights and all of the murals, which started Emory’s obsession with mural hunting while traveling." image={[Austin]}/>

                        <TimelineElement fit={false} index={1} description="" image={[ChicagoMural, SeattleMural]}/>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={2} description="Due to the pandemic, we didn’t see each other in person for 7 months. Emory was finally able to visit Adam in Boston in August for his birthday, but unfortunately, we couldn’t spend our anniversary in September together." image={[AdamBirthday]}/>
                    
                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div>
                    <h3 style={{"textAlign":"center"}}>Year 5</h3>
                    <div className="row timeline">
                        <div className="offset-timeline-element">
                            <TimelineElement fit={true} index={1} date="February 17, 2023" description="We didn’t travel together for 9 months due to the pandemic, but we decided to roadtrip from Boston to Acadia National Park. We enjoyed seeing the foliage and exploring the outdoors while being able to safely social distance." image={[Acadia]}/>

                            <TimelineElement fit={true} index={1}  description="Adam moved back to Pittsburgh in May 2021, and we enjoyed living together in the Highland Park area." image={[MovingTruck]}/>

                        </div>

                        <div className="offset-timeline-element">
                            <TimelineElement fit={true} index={2}  description="We visited the Ice Castle in New Hampshire, and it is one of the coolest places we’ve seen." image={[IceCastle]}/>

                            <TimelineElement fit={true} index={2}  description="Our families met in Arizona, and we went to the Grand Canyon and Sedona." image={[GrandCanyonFamily, GrandCanyonFamily2, Lift]}/>

                        </div>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={0} date="February 17, 2023" description="We traveled to Asheville, NC to celebrate our 5th anniversary. After a relaxing couples spa day and 3-hour multi-course dinner, Emory was in a food coma after we got back to our cabin, and she didn’t suspect a thing. She turned around, and there Adam was on one knee, proposing. She said yes!" image={[Proposal, Proposal2]}/>

                        <TimelineElement fit={false} index={1}  description="" image={[Proposal3, Proposal4, Proposal5]}/>
                    </div>

                    <div className="row timeline">
                        
                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div>
                    <h3 style={{"textAlign":"center"}}>Year 6</h3>
                    <div className="row timeline">
                        <div className="offset-timeline-element">
                            <TimelineElement fit={true} index={1}  description="We always wanted to see the cherry blossoms in Washington, D.C., and the timing finally worked out. We were able to visit during peak bloom, and it was beautiful." image={[CherryBlossom, CherryBlossom2]}/>

                            <TimelineElement fit={true} index={1} description="" image={[Bayoons2]}/>
                        </div>

                        <TimelineElement fit={false} index={2}  description="One of Emory’s top bucket list items was to go on a hot air balloon ride. Adam had booked a balloon ride during one of our previous trips to California, and it ended up getting canceled the day of due to weather. Adam had booked another balloon ride for our trip to Asheville and had planned on proposing during the ride, but unfortunately, that one also got canceled due to weather. Finally, during a trip to Arizona and to celebrate our 6th anniversary 3 weeks early, we were able to go on our first balloon ride, and it was amazing. We are looking forward to our next one!" image={[Bayoons, Bayoons3]}/>

                        <div style={{"width": "12%"}}></div>

                        <TimelineElement fit={false} index={0} date="February 17, 2023" description="After a roller coaster ride of house hunting in the summer/fall of 2022, we found the one. We spent our 6th anniversary doing our house inspection and closed on our first house on November 1, 2022." image={[House, Mario2, Mario3]}/>

                        <TimelineElement fit={false} index={1}  description="We both are huge Mario fans and had booked a trip to Japan for the Tokyo 2020 Olympics and to visit Super Nintendo World in Universal Studios Japan. Unfortunately, due to the pandemic, our trip was canceled. We were so excited to hear that a Super Nintendo World was opening in Universal Studios Hollywood, and we traveled to California 2 weeks after it opened to visit and celebrate Emory’s 30th birthday week." image={[Mario, Mario4]}/>
                    </div>
                </div>
                <br/>
                <hr style={{padding:"0px", height:"2px"}}></hr>
                <div style={{"width": "100%", "margin": "auto", "textAlign": "center", "hyphens": "auto"}}>
                    <h4>We got married!</h4>
                </div>
                <div style={{"width": "60%", "margin": "auto"}}>
                    
                    <div>
                        <img style={{"height": "auto", "width": "100%", "borderRadius": "16px", "marginBottom": "20px"}} src={married}/>
                    </div>
                </div>
                <br/>
            </div>
    )
}

export default TimelineWebUpdated;