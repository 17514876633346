import photo1 from '../img/WeddingPhotos/1.jpeg'
import photo2 from '../img/WeddingPhotos/2.jpeg'
import photo3 from '../img/WeddingPhotos/3.jpeg'
import photo4 from '../img/WeddingPhotos/4.jpg'
import photo5 from '../img/WeddingPhotos/5.jpg'
import photo6 from '../img/WeddingPhotos/6.jpeg'
import photo7 from '../img/WeddingPhotos/7.jpg'
import photo8 from '../img/WeddingPhotos/8.jpg'
import photo9 from '../img/WeddingPhotos/9.jpeg'
import photo10 from '../img/WeddingPhotos/10.jpeg'
import photo11 from '../img/WeddingPhotos/11.jpg'
import photo12 from '../img/WeddingPhotos/12.jpeg'
import photo13 from '../img/WeddingPhotos/13.jpg'
import photo14 from '../img/WeddingPhotos/14.jpeg'
import photo15 from '../img/WeddingPhotos/15.jpeg'
import photo16 from '../img/WeddingPhotos/16.jpg'
import photo17 from '../img/WeddingPhotos/17.jpg'
import photo18 from '../img/WeddingPhotos/18.jpg'
import photo19 from '../img/WeddingPhotos/19.jpg'
import photo20 from '../img/WeddingPhotos/20.jpeg'
import photo21 from '../img/WeddingPhotos/21.jpg'
import photo22 from '../img/WeddingPhotos/22.jpg'
import photo23 from '../img/WeddingPhotos/23.jpg'
import photo24 from '../img/WeddingPhotos/24.jpeg'
import photo25 from '../img/WeddingPhotos/25.jpg'
import photo26 from '../img/WeddingPhotos/26.jpg'
import photo27 from '../img/WeddingPhotos/27.jpg'
import photo28 from '../img/WeddingPhotos/28.jpg'
import photo29 from '../img/WeddingPhotos/29.jpeg'
import photo30 from '../img/WeddingPhotos/30.jpeg'


import PhotoGallery from './PhotoGallery'

import Photos from './Photos'

function WeddingPhotos() {
    let photoGalleryImages = [
        {"source": photo1, "thumb": photo1, "width": 2048, "height": 1366},
        {"source": photo2, "thumb": photo2,"width": 2048, "height": 3071},
        {"source": photo3, "thumb": photo3,"width": 2048, "height": 1365},
        {"source": photo4, "thumb": photo4,"width": 2048, "height": 1365},
        {"source": photo5, "thumb": photo5,"width": 1835, "height": 2753},
        {"source": photo6, "thumb": photo6,"width": 2048, "height": 1365},
        {"source": photo7, "thumb": photo7,"width": 2048, "height": 1365},
        {"source": photo8, "thumb": photo8,"width": 2048, "height": 3072},
        {"source": photo9, "thumb": photo9,"width": 2048, "height": 1365},
        {"source": photo10, "thumb": photo10,"width": 2048, "height": 1366},
        {"source": photo11, "thumb": photo11,"width": 2048, "height": 3072},
        {"source": photo12, "thumb": photo12,"width": 2048, "height": 1366},
        {"source": photo13, "thumb": photo13, "width": 2048, "height": 1365},
        {"source": photo14, "thumb": photo14,"width": 2048, "height": 3071},
        {"source": photo15, "thumb": photo15,"width": 2048, "height": 1366},
        {"source": photo16, "thumb": photo16,"width": 2048, "height": 1366},
        {"source": photo17, "thumb": photo17,"width": 2048, "height": 3072},
        {"source": photo18, "thumb": photo18,"width": 2048, "height": 1365},
        {"source": photo19, "thumb": photo19,"width": 2048, "height": 1365},
        {"source": photo20, "thumb": photo20,"width": 2048, "height": 3072},
        {"source": photo21, "thumb": photo21,"width": 2048, "height": 1365},
        {"source": photo22, "thumb": photo22,"width": 2048, "height": 1365},
        {"source": photo23, "thumb": photo23,"width": 2048, "height": 3072},
        {"source": photo24, "thumb": photo24,"width": 2048, "height": 1365},
        {"source": photo25, "thumb": photo25,"width": 2048, "height": 1365},
        {"source": photo26, "thumb": photo26,"width": 2048, "height": 1365},
        {"source": photo27, "thumb": photo27,"width": 2048, "height": 1365},
        {"source": photo28, "thumb": photo28,"width": 2048, "height": 1365},
        {"source": photo29, "thumb": photo29,"width": 2048, "height": 1365},
        {"source": photo30, "thumb": photo30,"width": 2048, "height": 1365},
    ]

    return (
        <div>
            <div>
                <h2>Our Wedding Photos</h2>
                <PhotoGallery images={photoGalleryImages}/>
            </div>

            <Photos/>
        </div>
    )
}

export default WeddingPhotos;