import Soergel1 from '../img/ThingsToDo/Things To Do/Soergel_s 1.jpg'
import Soergel2 from '../img/ThingsToDo/Things To Do/Soergel\'s1.png'
import Baron1 from '../img/ThingsToDo/Things To Do/Baron 1.jpg'
import Baron2 from '../img/ThingsToDo/Things To Do/Baron 2.jpg'
import Baron3 from '../img/ThingsToDo/Things To Do/Baron 3.jpg'
import ZooLanterns1 from '../img/ThingsToDo/Things To Do/Zoo.jpg'
import ZooLanterns2 from '../img/ThingsToDo/Things To Do/Zoo2.jpg'
import ZooLanterns3 from '../img/ThingsToDo/Things To Do/Zoo Lanterns.jpg'
import ZooEllie1 from '../img/ThingsToDo/Things To Do/Zoo Ellie 1.jpg'
import ZooEllie2 from '../img/ThingsToDo/Things To Do/Zoo Ellie 2.jpg'
import MountWashington1 from '../img/ThingsToDo/Things To Do/Pittsburgh Mount Washington 1.JPG'
import MountWashington2 from '../img/ThingsToDo/Things To Do/Mount Washington 2.jpg'
import Point from '../img/ThingsToDo/Things To Do/Pittsburgh Point.jpg'
import Phipps from '../img/ThingsToDo/Things To Do/Phipps.png'
import StationSquare from '../img/ThingsToDo/Things To Do/Pittsburgh Station Square.jpg'
import StationSquare2 from '../img/ThingsToDo/Things To Do/Pittsburgh Boat 1.jpg'
import ThingsToDoElement from './ThingsToDoElement'

import pittsburghsign from '../img/Memories/pittsburghsign.jpg'

import vendor1 from '../img/Vendors/1JanandJeff.jpg'
import vendor2 from '../img/Vendors/2Tony.jpg'
import vendor3 from '../img/Vendors/3Luanne.jpg'
import vendor4 from '../img/Vendors/4BistroToGo.jpeg'
import vendor5 from '../img/Vendors/5BistroToGo2.jpeg'
import vendor6 from '../img/Vendors/6Millies.jpeg'
import vendor7 from '../img/Vendors/7VendorTable.jpg'
import vendor8 from '../img/Vendors/8Debbie.jpg'
import vendor9 from '../img/Vendors/9Abby.jpg'
import vendor10 from '../img/Vendors/10Juelz.jpg'
import vendor11 from '../img/Vendors/11Brittany.jpg'
import vendor12 from '../img/Vendors/12Blumengarten.jpeg'
import vendor13 from '../img/Vendors/13Eventioneers.jpeg'

function Vendors() {
    return (
        <div className='things-to-do'>
            <h2>Dream Vendor Team</h2>
            <div>Executing a wedding is no easy task. We couldn’t have done it without our amazing vendor team!</div>
            <br/>
            <ThingsToDoElement
                name="Jan & Jeff Lopes"
                address=""
                address2=""
                description="A huge thanks to Jan & Jeff for maintaining the most stunning venue and being incredibly accommodating so we could bring our wedding vision to life at Gardens of Stonebridge!"
                image={vendor1}
                secondImage=""
                link=""
            />
            <br/>
            <ThingsToDoElement
                name="Tony Bingham"
                address=""
                address2=""
                description="Thanks to Tony for being the best officiant and for being so enthusiastic about personalizing our ceremony and delivering a memorable performance!"
                image={vendor2}
                secondImage=""
                link=""
                alt={true}
            />
            <br/>
            <ThingsToDoElement
                name="Luanne O’Brien"
                address=""
                address2=""
                description="Thanks to Luanne for being patient with us through so many dance lessons and helping us choreograph our perfect first dance!"
                image={vendor3}
                secondImage=""
                link=""
            />
            <br/>
            <ThingsToDoElement
                name="Bistro To Go Team"
                address=""
                address2=""
                description="Thanks to the whole Bistro To Go team for exceeding our vision in every way with the amazing food & service at our wedding!"
                image={vendor4}
                secondImage={vendor5}
                link=""
            />
            <br/>
            <ThingsToDoElement
                name="Millie’s Team"
                address=""
                address2=""
                description="Thanks to Millie’s for having the best ice cream and the truck team for scooping at our wedding!"
                image={vendor6}
                secondImage=""
                link=""
                alt={true}
            />
            <img className='random-pic' src={vendor7}></img>
            <br/>
            <h3>Catherine Acevedo & Tara Geyer from Acevedo Weddings</h3>
            <div>Thanks to Catherine and Tara for being our photo team!</div>
            <br/>
            <h3>Travis Chickness</h3>
            <div>Thanks to DJ Travis for keeping the good music and party going all night!</div>
            <br/>
            <h3>Nikki Wright and Team from Bubbly Bartending</h3>
            <div>A huge thank you to Nikki for being so dedicated to craft our signature mocktails and knocking it out of the park with our drink station!</div>
            <br/>
            <h3>Chris Kravetsky and Team from Legacy Films</h3>
            <div>Thanks to our video team for capturing the memories of our special day!</div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            
            <br/>
            <ThingsToDoElement
                name="Debbie"
                address=""
                address2=""
                description="Thanks to Debbie for being incredibly detail oriented and a seamstress wizard to bring Emory’s dress vision to life!"
                image={vendor8}
                secondImage=""
                link=""
            />
            <br/>
            <ThingsToDoElement
                name="Abby"
                address=""
                address2=""
                description="Thanks to Abby for being the biggest ray of sunshine and making our brows look so good!"
                image={vendor9}
                secondImage=""
                link=""
                alt={true}
            />
            <br/>
            <ThingsToDoElement
                name="Juelz & Brittany from Julea Beauty"
                address=""
                address2=""
                description="Thanks to Juelz (makeup) and Brittany (hair) for making me (Emory) look my best!"
                image={vendor10}
                secondImage={vendor11}
                link=""
            />
            <br/>
            <img className='random-pic' src={vendor12}></img>
            <h3>Blumengarten</h3>
            <div>Thanks to Sherry and the team for executing Emory’s dream wedding florals!</div>
            <br/>
            <h3>Amanda from EF Lighting</h3>
            <div>Thanks to Amanda for installing the twinkle lights to the tent for our day—it was the perfect magical touch!"</div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <h3>Eventioneers</h3>
            <div>Thanks to the Eventioneers team for supplying all of the rentals!</div>
        </div>
    )
}

export default Vendors;