import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import 'bootstrap/dist/css/bootstrap.min.css';

function NavigationArchive(props) {
    return (
        <section className="navigation">
            <header>
                <Navbar className="navbar-default" collapseOnSelect expand='sm' >
                    <Container>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav className="m-auto" activeKey="/">
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Home")} href="#home">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Story")} href="#story">Our Story</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Photos")} href="#photos">Photos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("WeddingParty")} href="#wedding-party">Wedding Party</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Questions")} href="#questions">Q & A</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Travel")} href="#travel">Travel</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("ThingsToDo")} href="#things-to-do">Things to Do</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Registry")} href="#things-to-do">Registry</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("RSVP")} href="#rsvp">RSVP</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </section>
    );
}

export default NavigationArchive;