import ThingsToDoElement from './ThingsToDoElement'

import address1 from '../img/Honeymoon/Honeymoon1.jpeg'
import address2 from '../img/Honeymoon/Honeymoon2.jpeg'
import burjarab from '../img/Honeymoon/Honeymoon3.jpeg'
import sal from '../img/Honeymoon/Honeymoon4.jpeg'
import burjkhalifa from '../img/Honeymoon/Honeymoon5.jpeg'
import mall from '../img/Honeymoon/Honeymoon6.jpeg'
import hotairballoon1 from '../img/Honeymoon/Honeymoon7.jpg'
import hotairballoon2 from '../img/Honeymoon/Honeymoon8.jpeg'
import hotairballoon3 from '../img/Honeymoon/Honeymoon9.jpeg'
import hotairballoon4 from '../img/Honeymoon/Honeymoon10.jpeg'
import penguin from '../img/Honeymoon/Honeymoon11.jpeg'
import aura1 from '../img/Honeymoon/Honeymoon12.jpeg'
import aura2 from '../img/Honeymoon/Honeymoon13.jpeg'
import palmbaloon1 from '../img/Honeymoon/Honeymoon14.jpeg'
import palmbaloon2 from '../img/Honeymoon/Honeymoon15.jpeg'
import maldivefood1 from '../img/Honeymoon/Honeymoon16.jpeg'
import maldivefood2 from '../img/Honeymoon/Honeymoon17.jpeg'
import beach1 from '../img/Honeymoon/Honeymoon18.jpeg'
import beach2 from '../img/Honeymoon/Honeymoon19.jpeg'
import spa1 from '../img/Honeymoon/Honeymoon20.jpg'
import spa2 from '../img/Honeymoon/Honeymoon21.jpeg'
import timer1 from '../img/Honeymoon/Honeymoon22.jpg'
import timer2 from '../img/Honeymoon/Honeymoon23.jpeg'

function HoneymoonHighlights() {
    return (
        <div className='things-to-do'>
            <h2>Honeymoon Highlights</h2>
            <div>Thank you so much to all of you who contributed to our honeymoon fund. It was a trip of a lifetime, and we had the most amazing time!
            </div>
            <br/>
            <h3>Dubai</h3>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Enjoying views of Palm Jumeirah from Address Beach Resort"
                image={address1}
                secondImage={address2}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Tour of Burj Al Arab and lunch at SAL"
                image={burjarab}
                secondImage={sal}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Burj Khalifa and Dubai Mall"
                image={burjkhalifa}
                secondImage={mall}
                link=""
                centertext={true}
            />
            <img className='smaller-pic' src={hotairballoon1}></img>
            <img className='smaller-pic' src={hotairballoon2}></img>
            <img className='smaller-pic' src={hotairballoon3}></img>
            <img className='smaller-pic' src={hotairballoon4}></img>
            <br/>
            <div style={{fontSize: "large"}}>Hot air balloon ride over the desert and breakfast with camels</div>
            <br/>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Hanging out with penguins at Ski Dubai"
                image={penguin}
                secondImage=""
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Admiring the 360 views of Palm Jumeirah from AURA SKYPOOL"
                image={aura1}
                secondImage={aura2}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Tethered balloon ride over Palm Jumeirah"
                image={palmbaloon1}
                secondImage={palmbaloon2}
                link=""
                centertext={true}
            />
            <br/>
            <h3>Maldives</h3>
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Enjoying island food & mocktails"
                image={maldivefood1}
                secondImage={maldivefood2}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Adam had a blast going snorkeling and scuba diving, while Emory enjoyed beach time."
                image={beach1}
                secondImage={beach2}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Enjoying spa time together"
                image={spa1}
                secondImage={spa2}
                link=""
                centertext={true}
            />
            <br/>
            <ThingsToDoElement
                name=""
                address=""
                address2=""
                description="Our honeymoon photoshoot got rained out, as is our luck, so we took a few of our own before the 38 hours of travel back home."
                image={timer1}
                secondImage={timer2}
                link=""
                centertext={true}
            />
        </div>
    )
}

export default HoneymoonHighlights;