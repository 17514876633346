import Love from '../img/Love/1 Alexandria ILY Day.jpg'
import Love2 from '../img/Love/2 Austin ILY.jpg'
import Love3 from '../img/Love/3 Boston XO.jpg'
import Love4 from '../img/Love/4 NYC Love.JPG'
import Love6 from '../img/Love/6 Philly Amor.png'
import Love7 from '../img/Love/7 Vegas Love Sign.png'
import Love8 from '../img/Love/8 MOIC Heart.JPG'
import Love9 from '../img/Love/9 Alexandria ILY Night.jpg'

import TimelineWebUpdated from './TimelineWebUpdated'

function StoryUpdated() {
    return (
        <div className="story">
            <h2>Our Love Story</h2>
            <div className="justify-content-center row">
                <div style={{"width": "25%"}}>
                    <img style={{"aspectRatio": "auto", "height": "auto", "maxWidth": "100%", "width": "auto","borderRadius": "16px", "marginBottom": "20px"}} src={Love}/>
                </div>
                <div style={{"width": "25%"}}>
                    <img style={{"height": "auto", "maxWidth": "100%", "width": "auto","borderRadius": "16px", "marginBottom": "20px"}} src={Love2}/>
                </div>
                <div style={{"width": "25%"}}>
                    <img style={{"height": "auto", "maxWidth": "100%", "width": "auto", "borderRadius": "16px", "marginBottom": "20px"}} src={Love3}/>
                </div>
                <div style={{"width": "fit-content", "maxWidth": "25%"}}>
                    <img style={{"maxHeight": "230px", "width": "fit-content", "maxWidth": "100%", "borderRadius": "16px", "marginBottom": "20px"}} src={Love4}/>
                </div>
            </div>
            <div style={{"width": "80%", "margin": "auto", "textAlign": "justify", "hyphens": "auto"}}>We first met on March 25, 2016, when Adam traveled from Arizona to Pittsburgh to attend a prospective student open house at Carnegie Mellon University, where Emory works. He remembered her, but she didn't remember him since she met many students that day. Adam ended up coming to CMU for graduate school in August 2016, and we met again during student orientation. Adam would stop by the front desk to make small talk with Emory as much as he could, and one time, he asked her for her number. Emory, not available at the time, politely refused. Adam, persistent, asked if he could add her on Facebook, and she reluctantly agreed. Emory was taking evening classes at CMU, and we began doing homework together at Schenley Plaza between the time Emory got off from work and had class. We discovered we like a lot of the same music artists and quickly became close friends. Emory realized no one made her happier, and a month later, after many late nights of homework and some unofficial dates, we started dating on September 23, 2016. From then on, we truly became best friends, supporting each other through tough semesters and unwinding by exploring Pittsburgh together. In May 2018, Adam graduated from CMU. In return for his full scholarship, he had a 2-year commitment to work for the government, and he accepted a job in Boston, MA. Emory flew to Boston to help him move in, and we traveled between Pittsburgh and Boston to make long distance work until May 2021, when Adam was able to move back to Pittsburgh. On our 5th anniversary, September 23, 2021, Adam asked Emory if she would marry him, while we were on vacation in Asheville, NC. Of course, Emory said yes!<br/><br/>
            </div>

            <div className="justify-content-center row">
            <div style={{"width": "fit-content", "maxWidth": "25%"}}>
                    <img style={{"maxHeight": "230px", "width": "fit-content", "maxWidth": "100%", "borderRadius": "16px", "marginBottom": "20px"}} src={Love6}/>
                </div>
                <div style={{"width": "25%"}}>
                    <img style={{"aspectRatio": "auto", "height": "auto", "maxWidth": "100%", "width": "auto","borderRadius": "16px", "marginBottom": "20px"}} src={Love7}/>
                </div>
                <div style={{"width": "25%"}}>
                    <img style={{"height": "auto", "maxWidth": "100%", "width": "auto","borderRadius": "16px", "marginBottom": "20px"}} src={Love8}/>
                </div>
                <div style={{"width": "25%"}}>
                    <img style={{"height": "auto", "maxWidth": "100%", "width": "auto", "borderRadius": "16px", "marginBottom": "20px"}} src={Love9}/>
                </div>
            </div>
            <hr style={{padding:"0px", height:"2px"}}></hr>
            <br/>
            <TimelineWebUpdated/>
        </div>
    )
}

export default StoryUpdated;