import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import addDays from 'date-fns/addDays';

import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navigation from './components/Navigation';
import NavigationArchive from './components/NavigationArchive';
import Home from './components/Home';
import HomeArchive from './components/HomeArchive';
import Story from './components/Story';
import Travel from './components/Travel';
import ThingsToDo from './components/ThingsToDo';
import Photos from './components/Photos';
import WeddingParty from './components/WeddingParty';
import Questions from './components/Questions';
import Registry from './components/Registry';
import RSVP from './components/RSVP';
import Footer from './components/Footer';

import AdamAndEmory from './img/Graphics/Header.png'
import ThankYous from './components/ThankYous';
import WeddingPhotos from './components/WeddingPhotos';
import HoneymoonHighlights from './components/HoneymoonHighlights';
import Vendors from './components/Vendors'
import StoryUpdated from './components/StoryUpdated';

function App() {

  const [page, setPage] = useState("Home");

  const handler = (newPage) => {
    console.log("Change page to " + newPage)
    setPage(newPage);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <img className="title" src={AdamAndEmory}/>
            <Navigation handler={handler}></Navigation>
            {page === "Home" ? <Home/> : ""}
            {page === "ThankYous" ? <ThankYous/> : ""}
            {page === "Vendors" ? <Vendors/> : ""}
            {page === "Photos" ? <WeddingPhotos/> : ""}
            {page === "HoneymoonHighlights" ? <HoneymoonHighlights/> : ""}
            {page === "Story" ? <StoryUpdated/> : ""}
            <Footer/>
          </div>}>
        </Route>
        <Route path="/archive/" element={
          <div className="App">
            <img className="title" src={AdamAndEmory}/>
            <NavigationArchive handler={handler}></NavigationArchive>
            {page === "Home" ? <HomeArchive/> : ""}
            {page === "Story" ? <Story/> : ""}
            {page === "Photos" ? <Photos/> : ""}
            {page === "WeddingParty" ? <WeddingParty/> : ""}
            {page === "Questions" ? <Questions/> : ""}
            {page === "Travel" ? <Travel/> : ""}
            {page === "ThingsToDo" ? <ThingsToDo/> : ""}
            {page === "Registry" ? <Registry/> : ""}
            {page === "RSVP" ? <RSVP/> : ""}
            <Footer/>
          </div>}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
