import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import 'bootstrap/dist/css/bootstrap.min.css';

function Navigation(props) {
    return (
        <section className="navigation">
            <header>
                <Navbar className="navbar-default" collapseOnSelect expand='sm' >
                    <Container>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav className="m-auto" activeKey="/">
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Home")} href="#home">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("ThankYous")} href="#thankyous">Thank Yous</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Vendors")} href="#vendors">Dream Team</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Photos")} href="#photos">Photos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("HoneymoonHighlights")} href="#honeymoonhighlights">Honeymoon Highlights</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => props.handler("Story")} href="#story">Our Story</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </section>
    );
}

export default Navigation;