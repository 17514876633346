import BannerPlaceholder from '../img/Home/WeddingRewindTop.jpeg';
import lollies from '../img/WeddingRecap/2 Lollipops.jpeg';
import drinks from '../img/WeddingRecap/3 Drinks.jpeg';
import apple from '../img/WeddingRecap/4 Apple Cider.jpg';
import horchata from '../img/WeddingRecap/5 Horchata.jpeg';
import horchata2 from '../img/WeddingRecap/6 Horchata 2.jpeg';
import hers from '../img/WeddingRecap/7 Hers.jpg';
import ours1 from '../img/WeddingRecap/8 Ours.jpeg';
import ours2 from '../img/WeddingRecap/9 Ours 2.jpeg';


function Home() {
    return (
        <div className="home">
            <img style={{width: "70%", "borderRadius": "16px"}} src={BannerPlaceholder}/>
            <div className="row justify-content-center">
                <h3>Wedding Rewind</h3>
                <h3 style={{margin: "10px", width: "fit-content"}}>September 23, 2023</h3>
                <div style={{padding:"0px", width:"2px"}} className="vr"></div>
                <h3 style={{margin: "10px", width: "fit-content"}}>Pittsburgh, PA</h3>
            </div>

            <div style={{"margin": "auto", width: "60%"}}>
                <h3>Wedding Highlights</h3>
                <div style={{position: "relative", "paddingBottom": "56.25%", "height": 0}}>
                    <iframe style={{position: "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}} width="560" height="315" src="https://drive.google.com/file/d/1LL4eiTisxy1TeHVJpoxC3FZO8WsoyCdN/preview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <br/>
            <br/>
            <div style={{"margin": "auto", width: "60%"}}>
                <h3>Wedding Ceremony</h3>
                <div style={{position: "relative", "paddingBottom": "56.25%", "height": 0}}>
                    <iframe style={{position: "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}} width="560" height="315" src="https://drive.google.com/file/d/1gzfB8oQOOorwf54Xod20rX4BzYHCY_mj/preview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <br/>
            <br/>
            <div style={{"margin": "auto", width: "60%"}}>
                <h3>Wedding Reception</h3>
                <div style={{position: "relative", "paddingBottom": "56.25%", "height": 0}}>
                    <iframe style={{position: "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%"}} width="560" height="315" src="https://drive.google.com/file/d/1V9PelbCOKlIKMlmqpx52Tmrvn_cWvN_R/preview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <br/>
            <br/>
            <div style={{"margin": "auto", width: "55%"}}>
                <h3>Soundtrack to Our Wedding</h3>
                <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/6jy5bmtBUkHm7cGKp4X6l8?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                <div style={{"text-align": "left"}}>
                    <div><strong>Processional</strong>: Rylynn - Andy McKee</div>
                    <div><strong>Bridal Entrance</strong>: You're Still the One - The Maine <br/> (One of Emory's favorite bands, who are coincidentally from Adam's hometown, Tempe, AZ!)</div>
                    <div><strong>Recessional</strong>: You Make My Dreams (Come True) - Daryl Hall & John Oates</div>
                    <div><strong>Grand Entrance</strong>: Peaches (Instrumental) - The Super Mario Bros. Movie<br/>(as big Super Mario fans, of course we had to sneak that in)</div>
                    <div><strong>First Dance</strong>: From the Ground Up - Dan + Shay</div>
                    <div><strong>Cake Cutting</strong>: "Married Life" from Up <br/>(Emory's favorite movie!)</div>
                </div>
            </div>
            <br/>
            <div style={{"margin": "auto", width: "50%"}}>
                <h3>Fun Details:</h3>
                <h4>Lollies</h4>
                <div>The mango lollipops (a mutual favorite flavor for us) used for the seating escort cards were from Melville Candy Company in the Boston area, where Adam previously lived.</div>
                <br/>
                <img style={{width: "70%", "borderRadius": "16px"}} src={lollies}/>
                <br/>
                <br/>
                <br/>
                <h4>Drinks</h4>
                <img style={{width: "70%", "borderRadius": "16px"}} src={drinks}/>
                <div>Each of the drinks had significant meaning including:</div>
                <br/>
                <ul style={{"text-align": "left"}}>
                    <li>
                        <div>Apple cider from Soergel’s Orchards, one of the first places we hung out off-campus</div>
                        <img style={{width: "70%", "borderRadius": "16px"}} src={apple}/>
                    </li>
                    <br/>
                    <li>Matcha horchata inspired by a drink that Adam ordered during a date at True Food Kitchen in Arizona, Emory took one sip of expecting to not like it like all other matcha things, and it turned out to be her favorite drink ever. We go back to TFK anytime we are close to one just for this drink!
                    <div className='row'>
                        <img style={{width: "40%", "borderRadius": "16px"}} src={horchata}/>
                        <img style={{width: "40%", "borderRadius": "16px"}} src={horchata2}/>
                    </div>
                    </li>
                    <br/>
                    <li>Signature mocktails:
                        <ul>
                            <li><strong>His</strong>: Coconut Lychee Punch – The perfect blend of Adam’s favorite flavors!</li>
                            <br/>
                            <li><div><strong>Hers</strong>: Watermelon Kiwi Lemonade – Inspired by Hash House a Go Go’s watermelon kiwi lemonade that Emory had during our bachelor/ette trip in Vegas</div>
                                <img style={{width: "70%", "borderRadius": "16px"}} src={hers}/>
                            </li>
                            <br/>
                            <li><strong>Ours</strong>: Peach Please – Inspired by Monterey Bay Fish Grotto mocktail that we had while dining at Monterey Bay Fish Grotto, one of our favorite restaurants in Pittsburgh
                                <div className='row'>
                                    <img style={{width: "40%", "borderRadius": "16px"}} src={ours1}/>
                                    <img style={{width: "40%", height: "fit-content", "borderRadius": "16px"}} src={ours2}/>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Home;