
import 'photoswipe/dist/photoswipe.css'
import '../index.css';

import { useState, useEffect } from "react";

import { Gallery, Item } from 'react-photoswipe-gallery'

function PhotoGallery(props) {
    var images = []
    var offset = false
    if ("images" in props)
        images = props.images
    if ("offset" in props)
        offset = props.offset

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    if (offset) {
        return (
            <div className="photogallery">
                <Gallery>
                    {images.map(function(object, i) {
                        if (i == 0) {
                            return <div className='big-thumbnail'><Item
                                original={object.source}
                                thumbnail={object.thumb}
                                width={object.width / object.height * 768}
                                height="768"
                                >
                                {({ ref, open }) => (
                                    <img className="photogallery-image-big" ref={ref} onClick={open} src={object.source} />
                                )}
                                </Item>
                                </div>
                        } else {
                            if ((i==4) || (i==5) || (i==9) || (i==10)) {
                                return (<Item
                                        original={object.source}
                                        thumbnail={object.thumb}
                                        width={object.width / object.height * 768}
                                        height="768"
                                        >
                                        {({ ref, open }) => (
                                            <img className="photogallery-image-even" ref={ref} onClick={open} src={object.source} />
                                        )}
                                    </Item>)
                            } else {
                                return (<Item
                                        original={object.source}
                                        thumbnail={object.thumb}
                                        width={object.width / object.height * 768}
                                        height="768"
                                        >
                                        {({ ref, open }) => (
                                            <img className="photogallery-image" ref={ref} onClick={open} src={object.source} />
                                        )}
                                    </Item>)
                            }
                        }
                    })}
                </Gallery>
            </div>
        )
    }
    else {
        return (
            <div className="photogallery">
                <Gallery>
                    {images.map(function(object, i) {
                            return <Item
                                    original={object.source}
                                    thumbnail={object.thumb}
                                    width={object.width / object.height * 768}
                                    height="768"
                                    >
                                    {({ ref, open }) => (
                                        <img className="photogallery-image" ref={ref} onClick={open} src={object.source} />
                                    )}
                                </Item>
                        }
                    )}
                </Gallery>
            </div>
        )
    }
}

export default PhotoGallery;